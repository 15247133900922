import * as React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import FacebookRoundedIcon from '@mui/icons-material/FacebookRounded';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import InstagramIcon from '@mui/icons-material/Instagram';
import TwitterIcon from '@mui/icons-material/Twitter';
import { Grid } from '@mui/material';

function Copyright() {
  return (
    <Typography variant="body2" color="text.secondary" align="left">
      {'Copyright © '}
      <Link color="inherit" href="/">
        Sri Venkateswara Enterprises
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
      <Typography></Typography>
      <Box margin="10px">
      {<FacebookRoundedIcon sx={{marginRight:"7px"}} color="primary"/>}
      {<InstagramIcon sx={{marginRight:"7px", color:"orangered"}}/>}
      {<TwitterIcon sx={{marginRight:"7px"}} color="primary"/>}
      {<LocationOnIcon sx={{marginRight:"7px"}} color="success"/>}
      </Box>
    </Typography>
  );
}

function Footer(props) {
  const { description, title } = props;

  return (
    <Box component="footer" sx={{ bgcolor: 'background.paper', py: 6 }}>
      <Container maxWidth="lg">
        <Grid container>
          <Grid md={6}>
          <Copyright />
          </Grid>
          <Grid md={6}>
          <Typography variant="body2" color="text.secondary" align="right">
    Powered by <Link color="inherit" href="https://www.6icube.com/" target="_blank">6icube Techno Consultants Pvt. Ltd.
      </Link>
</Typography>
          </Grid>
        </Grid>
        
      </Container>
    </Box>
  );
}

Footer.propTypes = {
  description: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};

export default Footer;