import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Badge from '@mui/material/Badge';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import { NavLink } from 'react-router-dom';
import CallIcon from '@mui/icons-material/Call';
import useMediaQuery from '@mui/material/useMediaQuery';



const settings = ['Profile', 'Account', 'Dashboard', 'Logout'];

function Navbar(props) {
    const [anchorElNav, setAnchorElNav] = React.useState(null);
    const [anchorElUser, setAnchorElUser] = React.useState(null);
    const pages = props.pages;
    const matches = useMediaQuery('(min-width:600px)');
    const handleOpenNavMenu = (event) => {
        setAnchorElNav(event.currentTarget);
    };
    const handleOpenUserMenu = (event) => {
        setAnchorElUser(event.currentTarget);
    };

    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
    };

    const handleCloseUserMenu = () => {
        setAnchorElUser(null);
    };

    return (
        <Box sx={{ flexGrow: 1 }}>
            <AppBar position="fixed" sx={{ backgroundColor: "#2E8B57" }}>
                <Container maxWidth="xl">
                    <Toolbar disableGutters>
                        <NavLink to="/">
                            <IconButton
                                size="medium"
                                edge="start"
                                color="inherit"

                                sx={{ mr: 0, margin: "0px" }}
                            >
                                <img src="https://www.logolynx.com/images/logolynx/37/379ce0ca11e6413fb0aff5580c67b9b5.jpeg" alt="sve" width="65" height="65" />
                            </IconButton>
                        </NavLink>

                        <Typography
                            variant="h6"
                            noWrap
                            sx={{ fontWeight: 'bold', margin: '0px', color: "white", fontFamily: "raleway" }}
                        >
                            {matches ? "Sri Venkateswara Enterprises" : ""}
                        </Typography>

                        <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
                            <IconButton
                                size="large"
                                aria-label="account of current user"
                                aria-controls="menu-appbar"
                                aria-haspopup="true"
                                onClick={handleOpenNavMenu}
                                color="inherit"
                            >
                                <MenuIcon />
                            </IconButton>
                            <Menu
                                id="menu-appbar"
                                anchorEl={anchorElNav}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'left',
                                }}
                                keepMounted
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'left',
                                }}
                                open={Boolean(anchorElNav)}
                                onClose={handleCloseNavMenu}
                                sx={{
                                    display: { xs: 'block', md: 'none' },
                                }}
                            >
                                {pages.map((page) => (
                                    <NavLink to={page} style={{ textDecoration: "none" }} sx={{ textTransform: "none" }}>
                                        <MenuItem key={page} onClick={handleCloseNavMenu}>
                                            <Typography textAlign="center" fontFamily="raleway" color="#2E8B57">{page}</Typography>
                                        </MenuItem>
                                    </NavLink>
                                ))}
                            </Menu>
                        </Box>
                        <Typography
                            variant="caption"

                            component="a"
                            href=""
                            sx={{
                                mr: 2,
                                display: { xs: 'flex', md: 'none' },
                                flexGrow: 1,
                                fontFamily: 'monospace',
                                fontWeight: 700,
                                letterSpacing: '.3rem',
                                color: 'inherit',
                                textDecoration: 'none',
                            }}
                        >
                            Sri Venkateswara Enterprises
                        </Typography>
                        <Box sx={{ flexGrow: 0.5 }} />
                        <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
                            {pages.map((page) => (
                                <NavLink to={page} activeClassName = "selected" activeStyle={{color:'yellow'}}>
                                   
                                    <Button 
                                        key={page}
                                        onClick={handleCloseNavMenu}
                                        sx={{ my: 2,color: "white", fontFamily: "raleway", textTransform: "none" }}
                                    >
                                        {page}
                                    </Button>
                                </NavLink>
                            ))}
                        </Box>

                        <Box sx={{ flexGrow: 0 }}>



                            <IconButton
                                size="medium"
                                aria-label="show 0 new notifications"
                                color="inherit"
                            >
                                <Badge badgeContent={0}>
                                    <a href="tel:+918885285711" >
                                        <CallIcon />
                                    </a>
                                </Badge>
                            </IconButton>
                            {/* <IconButton
                                    size="medium"
                                    edge="end"
                                    aria-label="account of current user"
                                    aria-controls={handleOpenUserMenu}
                                    aria-haspopup="true"
                                    onClick={handleOpenUserMenu}
                                    color="inherit"
                                >
                                    <AccountCircle />
                                </IconButton> */}


                            <Menu
                                sx={{ mt: '45px' }}
                                id="menu-appbar"
                                anchorEl={anchorElUser}
                                anchorOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                                keepMounted
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                                open={Boolean(anchorElUser)}
                                onClose={handleCloseUserMenu}
                            >
                                {settings.map((setting) => (
                                    <MenuItem key={setting} onClick={handleCloseUserMenu}>
                                        <Typography textAlign="center">{setting}</Typography>
                                    </MenuItem>
                                ))}
                            </Menu>
                        </Box>

                    </Toolbar>
                </Container>
            </AppBar>
        </Box>
    );
}
export default Navbar;