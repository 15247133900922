import { Box, Grid, Typography } from "@mui/material";
import Diageo from "../../Assets/Diageo.png";
import Moglix from "../../Assets/Moglix.png";
import Beam from "../../Assets/Beam.png";
import abd from "../../Assets/abd.png";
import nsl from "../../Assets/nsl.png";
import Liquors from "../../Assets/Liquors.png";
import Radico from "../../Assets/Radico.jpg";
import Soaring from "../../Assets/Soaring.png";
import Hccb from "../../Assets/Hccb.png";
import Amrutanjan from "../../Assets/Amrutanjan.jpg";
import Glukem from "../../Assets/Glukem.jpg";
import Vijaya from "../../Assets/Vijaya.jpg";
import scr from "../../Assets/scr.jpg";
import Mother from "../../Assets/Mother.png"


export default function Clients() {

  return (
    <>
    <Box marginTop="55px">
    <img alt="pl" src="https://img.freepik.com/free-vector/customer-giving-quality-feedback_74855-5482.jpg?w=996&t=st=1683453234~exp=1683453834~hmac=7a0757dca5cbf924ffae92f9d8e673c631f8ca7c848b29793907a9ff7fec32c4"/>
    <Typography variant="h5"
    sx={{color:"#2E8B57", fontFamily:"raleway", marginBottom:"18px"}}
    >Thank you for being our valued customer. Your Support and Trust in us is much appreciated.</Typography>
    </Box>
      <Box >
        


          {/* <Grid xs={4} sm={4} md={6}>
            <Typography variant="subtitle1"
            fontFamily="material-ui"
            color="orangered"
            sx={{textAlign:"justify"}}
            >
            Founded in 1986 by Shri Sama Ravinder Reddy with a vision to build a leading enterprise in industrial and commercial supplies. The company takes pride in its unwavering commitment to on-time delivery, happieness and satisfaction of its customer.
            </Typography>
            <Typography variant="subtitle1"
            fontFamily="material-ui"
            color="darkgreen"
            sx={{textAlign:"justify", marginTop:"20px"}}
            >
            Sri Venkateswara Enterprises sole purpose is to deliver Industrial Products and its allied services to buyers at their doorstep by using Innovative technology and resources to qualify as a preferred supplier. We aspire that our customers have delightful experience and always aim to achieve high level of customer satisfaction.
            </Typography>
            <Typography variant="h6"
            fontFamily="material-ui"
            color="blue"
            sx={{textAlign:"justify", marginTop:"20px"}}
            >
            The superlative quality, reliability and cost-effectiveness of our products have been appreciated by the clients across.
            </Typography>
          </Grid> */}


            <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }} sx={{ p: 5, alignContent: "centre" }}>
              <Grid xs={4} sm={4} md={4}  sx={{marginBottom:'20px'}}>
                <img alt="qa" src={Diageo} className="clien" />
              </Grid>
              <Grid xs={4} sm={4} md={4}  sx={{marginBottom:'20px'}} >
                <img alt="ws" src={Moglix} className="clien" />
              </Grid>
              <Grid xs={4} sm={4} md={4}  sx={{marginBottom:'20px'}}>
                <img alt="ed" src={Beam} className="clien" />
              </Grid>
              <Grid xs={4} sm={4} md={4}  sx={{marginBottom:'20px'}}>
                <img alt="rf" src={abd} className="clien" />
              </Grid>
              <Grid xs={4} sm={4} md={4}  sx={{marginBottom:'20px'}}>
                <img alt="tg" src={nsl} className="clien" />
              </Grid>
              <Grid xs={4} sm={4} md={4}  sx={{marginBottom:'20px'}}>
                <img alt="yh" src={Liquors} className="clien" />
              </Grid>
              <Grid xs={4} sm={4} md={4}  sx={{marginBottom:'20px'}}>
                <img alt="uj" src={Radico} className="clien" />
              </Grid>
              <Grid xs={4} sm={4} md={4}  sx={{marginBottom:'20px'}}>
                <img alt="ik" src={Soaring} className="clien" />
              </Grid>
              <Grid xs={4} sm={4} md={4}  sx={{marginBottom:'20px'}}>
                <img alt="ol" src={Hccb} className="clien" />
              </Grid>
              <Grid xs={4} sm={4} md={4}  sx={{marginBottom:'20px'}}>
                <img alt="ol" src={Amrutanjan} className="clien" />
              </Grid>
              <Grid xs={4} sm={4} md={4}  sx={{marginBottom:'20px'}}>
                <img alt="ol" src={Glukem} className="clien" />
              </Grid>
              <Grid xs={4} sm={4} md={4}  sx={{marginBottom:'20px'}}>
                <img alt="ol" src={scr} className="clien" />
              </Grid>
              <Grid xs={4} sm={4} md={4}  sx={{marginBottom:'20px'}}>
                <img alt="ol" src={Vijaya} className="clien" />
              </Grid>
              <Grid xs={4} sm={4} md={4}  sx={{marginBottom:'20px'}}>
                <img alt="ol" src={Mother} className="clien" />
              </Grid>
            </Grid>

      </Box>
    </>

  )
}