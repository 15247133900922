import './App.css';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Navbar from './Components/Navbar';
import AboutUs from './Pages/AboutUs';

import Clients from './Pages/Clients';
import Services from './Pages/Services';
import Footer from './Components/Footer';
import Categories from './Pages/Categories';
import LandingPage from './Pages/LandingPage';
import ScrollTo from './Components/ScrollTo';
import Contact from './Pages/Contact';
const pages = ['About Us', 'Categories', 'Services', 'Clients', 'Contact Us'];
function App() {
  return (
    <div className="App">
      <BrowserRouter>
      <ScrollTo/>
      <Navbar pages={pages}/>
      <Routes>
      <Route path="/" element={<LandingPage/>}/>
        <Route path="/About Us" element={<AboutUs/>}/>
        <Route path="/Contact Us" element={<Contact/>}/>
        <Route path="/Clients" element={<Clients/>}/>
        <Route path="/Services" element={<Services/>}/>
        <Route path="/Categories" element={<Categories/>}/>
      </Routes>
      </BrowserRouter>
      <Footer />
    </div>
  );
}

export default App;
