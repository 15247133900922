import React from 'react';
import { Box, Button, Card, Grid, Typography } from '@mui/material';
import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined';
import ShoppingCartOutlinedIcon from '@mui/icons-material/ShoppingCartOutlined';
import BusinessCenterOutlinedIcon from '@mui/icons-material/BusinessCenterOutlined';
import CardComponent from "../../Components/CardComponent"
import Testimonials from '../../Components/Testimonials';
import { NavLink } from 'react-router-dom';

function LandingPage() {
    const heroStyle = {
        minHeight: '100vh',
        // marginLeft: '10px',
        marginTop: '30px',
        backgroundImage: 'url(https://img.freepik.com/free-vector/abstract-digital-low-poly-connection-banner-design_1017-32434.jpg?w=1380&t=st=1683438889~exp=1683439489~hmac=699e1a300b6a9b8e68578f96475c34a4a3c4c0aeb67ffbea64490330022b1a81)',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',

    };

    const heroTextStyle = {
        color: 'white',
        textAlign: 'center',
    };

    const actionButtonStyle = {
        margin: '16px',
    };

    return (
        <div>
            <Grid container style={heroStyle}>
                <Grid item xs={12}>
                    <div style={heroTextStyle}>
                        <Typography variant="h3"
                            sx={{ color: "White", fontFamily: "raleway", marginBottom: "10px" }}
                        >Welcome to Sri Venkateswara Enterprises
                        </Typography>
                        <Typography variant="h6" color="lightcyan" fontFamily="raleway">We offer a wide range of Products & Services for all your Business Needs</Typography>
                        <NavLink to="/Contact Us">
                        <Button variant="contained" color="success" style={actionButtonStyle}>
                        
                            Contact Us
                           
                        </Button>
                        </NavLink>
                    </div>
                </Grid>
            </Grid>
            <Box sx={{ blockCards: (theme) => ({
    display: 'flex',
    justifyContent: 'center',
    gap: '65px',
    mt: '46px',
    flexDirection: 'column',
    alignItems: 'center',
    position: 'relative',
    '&:after': {
      background: 'none',
      [theme.breakpoints.up('lg')]: {
        content: "''",
        position: 'absolute',
        right: '-50px',
        bottom: '-100px',
        width: '250px',
        height: '412px',
        maxHeight: '412px'
      },
    },
    [theme.breakpoints.up('sm')]: {
      flexWrap: 'wrap',
      flexDirection: 'initial',
      alignItems: 'initial',
    },
    [theme.breakpoints.up('md')]: {
      mt: '95px',
      gap: '130px',
    },
  }),}}>
        <Grid container spacing={{ xs: 2, md: 3 }} sx={{ p:14}}>
            <Grid md={2}>

            </Grid>
            <Grid xs={12} sm={12} md={3}>
          <Card sx={{textAlign: 'center',
    boxShadow: 'none',
    p: '28px',
    boxSizing: 'border-box',
    border: '1px solid #2E8B57',
    backdropFilter: 'blur(4px)',
    borderRadius: '8px',
    width: '185px'}}>
            <BusinessCenterOutlinedIcon color="primary" fontSize="large"/>
            <Box sx={{m: '8px 0',
    color: '#2E8B57',
    fontSize: '24px',
    fontStyle:"bold",
    lineHeight: '29px'}}>25+</Box>
            <Box sx={{color: '#1976d2',
    fontSize: '20px',
    lineHeight: '32px',
    fontWeight: 500}}>Years of Experience</Box>
          </Card>
          </Grid>

          <Grid xs={12} sm={12} md={3}>
          <Card sx={{textAlign: 'center',
    boxShadow: 'none',
    p: '28px',
    boxSizing: 'border-box',
    border: '1px solid #2E8B57',
    backdropFilter: 'blur(4px)',
    borderRadius: '8px',
    width: '185px'}}>
            <PeopleAltOutlinedIcon color="primary" fontSize="large"/>
            <Box sx={{m: '8px 0',
    color: '#2E8B57',
    fontSize: '24px',
    lineHeight: '29px'}}>55+</Box>
            <Box sx={{color: '#1976d2',
    fontSize: '18px',
    lineHeight: '22px',
    fontWeight: 500}}>Valuable Happy Clients across India</Box>
          </Card>
          </Grid>

          <Grid xs={12} sm={12} md={3}>
          <Card sx={{textAlign: 'center',
    boxShadow: 'none',
    p: '28px',
    boxSizing: 'border-box',
    border: '1px solid #2E8B57',
    backdropFilter: 'blur(4px)',
    borderRadius: '8px',
    width: '185px'}}>
            <ShoppingCartOutlinedIcon color="primary" fontSize="large"/>    
            <Box sx={{m: '8px 0',
    color: '#2E8B57',
    fontSize: '20px',
    lineHeight: '28px'}}>Wide Range of Products</Box>
            <Box sx={{color: '#1976d2',
    fontSize: '15px',
    lineHeight: '20px',
    fontWeight: 500}}>You Name it, We Supply it</Box>
          </Card>
          </Grid>
          <Grid md={2}>

</Grid>
          </Grid>
        </Box>
            <Grid container >
                <Grid item xs={12} sx={{marginBottom:"20px"}}>
                    <Typography variant="h4" align="center" fontFamily="Raleway" color="Orangered" gutterBottom>
                        Our Products and Services
                    </Typography>
                </Grid>
                <Grid item xs={12} md={3}>

                    <CardComponent
                        image="https://t4.ftcdn.net/jpg/01/19/24/31/240_F_119243173_u4kmtvxLekusRisbbV9rJaaEsWRHKRf5.jpg"
                        cardcontent={"Hand Tools"}
                    />

                </Grid>
                <Grid item xs={12} md={3}>

                    <CardComponent
                        image="https://t4.ftcdn.net/jpg/02/19/02/37/240_F_219023764_x4GO9aTnDUkxf6yidyO7tXonmJ7I2Hdp.jpg"
                        cardcontent={"Fabrications & Welding "}
                    />

                </Grid>
                <Grid item xs={12} md={3}>

                    <CardComponent
                        image="https://t3.ftcdn.net/jpg/04/16/23/60/240_F_416236080_iUvBR5Z3rpYoCF0SPQF9Tk41uXIDcn8B.jpg"
                        cardcontent={"Bearings & Power Transmission"}
                    />

                </Grid>
                <Grid item xs={12} md={3}>

                    <CardComponent
                        image="https://t4.ftcdn.net/jpg/00/82/26/31/240_F_82263145_ileEZAdzH62kC1heFapQmfbO7azXBbxj.jpg"
                        cardcontent={"Hydraulics"}
                    />

                </Grid>
                <Grid item xs={12} md={3}>

                <CardComponent
                            image="https://t4.ftcdn.net/jpg/01/01/62/13/240_F_101621334_9gS31QSWz05XsISdoq7HvwhXEClwvHhu.jpg"
                            cardcontent={"All types of Pipes & Fittings"}
                            />
                </Grid>
                <Grid item xs={12} md={3}>

                <CardComponent
                            image="https://t3.ftcdn.net/jpg/00/57/53/32/240_F_57533203_GVjGOHZ7XMAHbIn8wM33kpKn1quXFCkM.jpg"
                            cardcontent={"MS Structural Steel"}
                            />

                </Grid>
                <Grid item xs={12} md={3}>

                <CardComponent
                            image="https://t4.ftcdn.net/jpg/02/20/77/57/240_F_220775744_dosJCTNpy8zBidOGOZB6Xij2DArGNKRq.jpg"
                            cardcontent={"Industrial Chemicals"}
                            />

                </Grid>
                <Grid item xs={12} md={3}>

                <CardComponent
                            image="https://t3.ftcdn.net/jpg/02/55/31/92/240_F_255319205_viUZLjQ0TihbK51vS8YXS1kpxjhRFEn1.jpg"
                            cardcontent={"Industrial Lubricants"}
                            />

                </Grid>
                
                <Grid item xs={12} md={3}>

                <CardComponent
                            image="https://t4.ftcdn.net/jpg/03/79/61/93/240_F_379619334_FEwWZPLZZc518BdMQcLtGc9DuGemmXQs.jpg"
                            cardcontent={"General Stationery"}
                            />

                </Grid>
                <Grid item xs={12} md={3}>

                    <CardComponent
                        image="https://t3.ftcdn.net/jpg/00/86/09/02/240_F_86090219_AgQYqc6e5WTemRT4gUBm9nWGfz3dmBan.jpg"
                        cardcontent={"Security"}
                    />

                </Grid>
                <Grid item xs={12} md={3}>

                <CardComponent
                            image="https://img.freepik.com/premium-photo/rendering-electric-water-pum_311470-101.jpg?size=626&ext=jpg&ga=GA1.2.164830262.1685001741&semt=ais"
                            cardcontent={"Pumps & Motors"}
                            />

                </Grid>
                <Grid item xs={12} md={3}>

                    <CardComponent
                        image="https://t4.ftcdn.net/jpg/05/43/21/15/240_F_543211583_4NJZ9A062sNs4vtlA1pA09oYL2SbGWEU.jpg"
                        cardcontent={"Hardware"}
                    />

                </Grid>

            </Grid>

            <Box sx={{marginTop:"40px"}}>
            <Typography variant="h4" align="center" fontFamily="Raleway" color="#2E8B57" gutterBottom>
                        Our Client Testimonials
                    </Typography>

            <Testimonials/>
            </Box>
        </div>
    );
}

export default LandingPage;
