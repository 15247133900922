import React from 'react';
import { Container, Typography, Grid, Card, CardContent } from '@mui/material';
import './Testimonials.css';

function Testimonials() {
  const testimonials = [
    {
      id: 1,
      name: 'T Satish Babu',
      title: 'Manager - Maintenance & Production',
      comment: '"I am very satisfied with the service provided by this company. The team was very professional and responsive to my needs."',
    },
    {
      id: 2,
      name: 'Vrushali Pol',
      title: 'Manager - Enterprise Sales',
      comment: '"I have been a customer of this company for several years now and I have always been impressed with their pompt reply, commited work & good with market price."',
    },
    {
      id: 3,
      name: 'J Praven Kumar Reddy',
      title: 'Junior Engineer - Mechanical',
      comment: '"The firm so supportive, creative and spontaneous to work. They support us 24/7, even in odd times and their creativeness is so amazing. Thanks for the support."',
    },
    {
        id: 4,
        name: 'Subramanian Viswanathan',
        title: 'Production Manager',
        comment: '"I have been a client to this firm from a long time. Almost all products & services will be available at very reasonable prices."',
      },
      {
        id: 5,
        name: 'G Shivanand Basavaraj',
        title: 'Manager - Purchase',
        comment: '"The team over there has always been proactive in answering us & maintaining a good relation with us. Thanks for the services."',
      },
  ];

  return (
    <Container maxWidth="lg" className="testimonials-container">
      <Grid container spacing={4} className="testimonials-grid">
        {testimonials.map((testimonial) => (
          <Grid item key={testimonial.id} xs={12} md={4}>
            <Card className="testimonial-card">
              <CardContent>
                <Typography variant="h6" gutterBottom className="testimonial-name">
                  {testimonial.name}
                </Typography>
                <Typography variant="subtitle1" color="text.secondary" gutterBottom className="testimonial-title">
                  {testimonial.title}
                </Typography>
                <Typography variant="body1" component="p" className="testimonial-comment">
                  {testimonial.comment}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
}

export default Testimonials;