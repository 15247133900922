import React, { useState, useRef } from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { Grid } from '@mui/material';
import Divider from '@mui/material/Divider';
import CallIcon from '@mui/icons-material/Call';
import EmailIcon from '@mui/icons-material/Email';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import emailjs from '@emailjs/browser';

const theme = createTheme();
const initialFormValues = {
  user_name: "",
  mobile: "",
  user_email: "",
  product_specification: "",
  quantity: ""
}
export default function Contact() {
  const form = useRef();
  const [enquiryFormValues, setEnquiryFormValues] = useState(initialFormValues);
  let { user_name, user_email, mobile, product_specification, quantity } = enquiryFormValues;

  const sendEmail = (event) => {
    event.preventDefault();
    console.log("form", form.current)

    emailjs.sendForm('service_szbe9z2', 'template_m6b0e7w', form.current, 'L85sm7n7LF67B0dBR')
      .then((result) => {
        console.log(result.text);
      }, (error) => {
        console.log(error.text);
      });

    setEnquiryFormValues(initialFormValues)
  };

  const handleFormChange = (e) => {
    const { name, value } = e.target;
    setEnquiryFormValues({ ...enquiryFormValues, [name]: value })
  }


  return (
    <>

      {/* <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }} sx={{alignContent:"center", alignItems:"center"}}>
      <Grid xs={12} sm={6} md={2}>

</Grid>
        <Grid md={4} sm={12} xs={12}> */}
          <Box marginTop="40px">
            <img alt="pl" src="https://img.freepik.com/free-vector/flat-design-illustration-customer-support_23-2148887720.jpg?w=740&t=st=1683726977~exp=1683727577~hmac=9893196b239ec6fcceaf168e2acd2092f515124eb5b133d859a87fd54c6e02cd" />
          </Box>



          <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }} sx={{alignContent:"center", alignItems:"center"}}>
        
       
        <Grid xs={4} sm={4} md={6} >
        <Box sx={{ marginTop: "25px", marginLeft:"15px",  borderRadius:"25px", p: 2 }}>
          <Typography variant="h4" fontFamily="Raleway" color="secondary" textAlign="center" marginBottom="10px"> Connect</Typography>
          <Divider />
          <Typography variant="h5" fontFamily="Raleway" textAlign="center" marginTop="10px" color="orangered" fontWeight="bold">Sri Venkateswara Enterprises</Typography>

          <Box sx={{marginTop:"15px"}}>
          <CallIcon/>
          <Typography variant='h5' color='success.main' fontFamily='Raleway' sx={{}}>Call Us</Typography>
          <Typography variant="subtitle1" sx={{fontWeight:"bold"}}>
            +919849044476
          </Typography>
          </Box>

          <Box sx={{marginTop:"20px"}}>
          <EmailIcon/>
          <Typography variant='h5' color='success.main' fontFamily='Raleway' sx={{}}>Email</Typography>
          <Typography variant="subtitle1" sx={{fontWeight:"bold"}}>
            enquiry@sve.enterprises
          </Typography>
          </Box>

          <Box sx={{marginTop:"20px"}}>
          <LocationOnIcon />
          <Typography variant='h5' color='success.main' fontFamily='Raleway' sx={{}}>Regd. Office</Typography>
          <Typography variant="subtitle1" sx={{fontWeight:"bold"}}>
            12-1-508/89/5, Laxminagar, North Lallaguda, Hyderabad, Telangana - 500017, India
          </Typography>
          </Box>

        </Box>
      </Grid>

      <Grid xs={4} sm={4} md={6}>
          <Box sx={{ marginTop: "80px", justifyContent: 'center', alignItems: 'center' }}>
            <ThemeProvider theme={theme}>
              <Container component="main" maxWidth="xs">
                <CssBaseline />
                <Box
                  sx={{
                    marginTop: 1,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                  }}
                >
                  <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                    <MailOutlineIcon />
                  </Avatar>
                  <Typography component="h1" variant="h5">
                    Contact Us
                  </Typography>
                  <form ref={form} noValidate sx={{ mt: 1 }}>
                    <TextField
                      margin="normal"

                      fullWidth
                      id="Name"
                      label="Name"
                      name="user_name"
                      autoComplete="Name"
                      autoFocus
                      value={user_name}
                      onChange={handleFormChange}
                    />
                    <TextField
                      margin="normal"

                      fullWidth
                      name="mobile"
                      label="mobile"
                      type="mobile"
                      id="mobile"
                      autoComplete="mobile"
                      value={mobile}
                      onChange={handleFormChange}
                    />
                    <TextField
                      margin="normal"

                      fullWidth
                      id="user_email"
                      label="user_email"
                      name="user_email"
                      autoComplete="user_email"
                      value={user_email}
                      onChange={handleFormChange}
                    />
                    <TextField
                      margin="normal"

                      fullWidth
                      id="product_specification"
                      label="product_specification"
                      name="product_specification"
                      autoComplete="product_specification"
                      value={product_specification}
                      onChange={handleFormChange}
                    />
                    <TextField
                      margin="normal"

                      fullWidth
                      id="quantity"
                      label="quantity"
                      name="quantity"
                      autoComplete="quantity"
                      value={quantity}
                      onChange={handleFormChange}

                    />

                    <Button

                      variant="contained"
                      sx={{ mt: 3, mb: 2 }}
                      onClick={sendEmail}
                    >
                      Submit
                    </Button>

                  </form>

                  {/* <form ref={form} onSubmit={sendEmail}>
        <label>Name</label>
        <input type="text" name="user_name" />
        <label>Email</label>
        <input type="email" name="user_email" />
        <label>Product Specification</label>
        <textarea name="product_specification" />
        <label>Quantity</label>
        <textarea type="text" name="quantity" />
        <input type="submit" value="Send" />
      </form> */}
                </Box>

              </Container>
            </ThemeProvider>
          </Box>
        </Grid>
      </Grid>
     
    </>
  );
}