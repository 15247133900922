import * as React from 'react';
import Grid from '@mui/material/Grid';
import BasicCard from "../../Components/BasicCard";
import { Box, Typography } from '@mui/material';

const services = [
    {
        image: "https://i.ibb.co/RHqY4Cc/Tunnel-Rinsing.jpg",
        title: "Tunnel Rinsing Machine",
        description: "In this machine the bottles are manually loaded into the pockets of tunnels and then the cleaning media is sprayed inside the inverted bottle in three stages and then the cleaned bottles are manually unloaded from the pockets of the machine."
    }
]
const service = [
    {
        image: "https://i.ibb.co/C70kN4z/Filling-Machine.jpg",
        title: "Filling Machine",
        description: "In this machine, the tip of the nozzle has two passages. From one of the passage, the product comes out and from the other one the air is vented out. This filling is best suited where the level of the product is maintained in the bottle."
    }
]
const servic = [
    {
        image: "https://i.ibb.co/YcrrjtY/Cap-Sealing-Machine.png",
        title: "Cap Sealing Machine",
        description: "These cappers are suitable for plastic screw caps. In these the caps are placed on the bottle and then rotated so that the cap screws onto threads on the neck of a bottle. These capping heads are suitable for guala caps that are specially designed luxury caps for spirits."
    }
]
const servi = [
    {
        image: "https://i.ibb.co/5kv6CZc/Sticker-Labelling-Machine.jpg",
        title: "Sticker Labelling Machine",
        description: "These are in-line sticker labelling machines for front, back and wrap around labels using the best label dispensers. The various kinds of peel systems allow placing of labels on assorted shapes of bottles without any bubbles or wrinkles."
    }
]
const serv = [
    {
        image: "https://i.ibb.co/x74KTdV/Pick-and-place.png",
        title: "Pick & Place Machine",
        description: "These machines are designed and manufactured to pick-up finished bottles from conveyor with the help of bellow heads and drop& place the finished product in carton. The operation begins with the feeding of finished bottles through slot conveyor."
    }
]
const ser = [
    {
        image: "https://i.ibb.co/V3cHzc2/Carton-Sealing-Machine.jpg",
        title: "Carton Sealer Machine",
        description: "The cartons leaving the carton erector pass through the tape application head in a carton sealer which puts tape on one side of the open cartons and closes it to form a one-sided open carton which is then fed into the case packer for further operation."
    }
]

export default function Services() {
    return (
        <>
            <Box sx={{ marginTop: "65px" }}>
            <img alt="pl" src="https://img.freepik.com/premium-vector/teamwork-finding-new-ideas-little-people-launch-mechanism-search-new-solutions-vector_566886-659.jpg?size=626&ext=jpg&ga=GA1.1.206460874.1683438467&semt=sph"/>
    <Typography variant="h4"
    sx={{color:"#2E8B57", fontFamily:"raleway", marginBottom:"1px"}}
    >We offer a Wide range of Spares & services for</Typography>
        <Typography variant="h6"
    sx={{color:"Slateblue", fontFamily:"raleway", marginBottom:"15px"}}
    >Beverage, Liquor & Spirits, Beer, Food and Pharmaceutical Industries</Typography>

                <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }} sx={{ p: 2 }}>


                    <Grid item xs={4} sm={4} md={4}>

                        {
                            services.map((services) => {
                                return <BasicCard image={services.image} title={services.title} description={services.description} />
                            })
                        }

                    </Grid>
                    <Grid item xs={4} sm={4} md={4}>
                        {
                            service.map((service) => {
                                return <BasicCard image={service.image} title={service.title} description={service.description} />
                            })
                        }
                    </Grid>
                    <Grid item xs={4} sm={4} md={4}>
                        {
                            servic.map((servic) => {
                                return <BasicCard image={servic.image} title={servic.title} description={servic.description} />
                            })
                        }
                    </Grid>
                </Grid>
            </Box>
            <Box sx={{  }}>
                
                <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }} sx={{ p: 2 }}>

                <Grid item xs={4} sm={4} md={4}>
{
    servi.map((servi) => {
        return <BasicCard image={servi.image} title={servi.title} description={servi.description} />
    })
}
</Grid>
<Grid item xs={4} sm={4} md={4}>
{
    serv.map((serv) => {
        return <BasicCard image={serv.image} title={serv.title} description={serv.description} />
    })
}
</Grid>
<Grid item xs={4} sm={4} md={4}>
{
    ser.map((ser) => {
        return <BasicCard image={ser.image} title={ser.title} description={ser.description} />
    })
}
</Grid>
                </Grid>
            </Box>


        </>
    )
}