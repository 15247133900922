import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';

export default function CardComponent(props) {
    const image=props.image;
    const title=props.title;
    const description=props.description;


  return (
    <Card sx={{ margin:'5px' }}>
      
        <CardMedia
          component="img"
          height="200"
          image={image}
          alt="category"
        />
        <CardContent>
         
          <Typography variant="h6" color="text">
           {title}
          </Typography>
          <Typography variant="body2" color="text.secondary">
           {description}
          </Typography>
        </CardContent>
      
    </Card>
  );
}